import "./App.css";
import { Grid, Card, Button, Page, Text, Link, Image } from "@geist-ui/core";
import { Github, Globe } from "@geist-ui/icons";

const accentColor = "#83B5D1";
const accentedTextStyle = { color: accentColor, fontWeight: "600" };

function App() {
  return (
    <>
      <Page>
        <Page.Header>
          <Grid.Container justify={"space-between"}>
            <Grid xs={24} md={1}>
              <Image height="75px" src="/logo.png" />
            </Grid>
            <Grid xs={24} md={6}>
              <Grid.Container justify={"space-around"}>
                <Grid xs={8} style={{ textAlign: "center" }}>
                  <Link
                    href={"#projects"}
                    style={{
                      lineHeight: "75px",
                      display: "block",
                      width: "100%",
                    }}
                  >
                    Projects
                  </Link>
                </Grid>
                <Grid xs={8} style={{ textAlign: "center" }}>
                  <Link
                    href={"https://blog.kengli.sh"}
                    style={{
                      lineHeight: "75px",
                      display: "block",
                      width: "100%",
                    }}
                  >
                    Blog
                  </Link>
                </Grid>
                <Grid xs={8} style={{ textAlign: "center" }}>
                  <Link
                    href={"#contact"}
                    style={{
                      lineHeight: "75px",
                      display: "block",
                      width: "100%",
                    }}
                  >
                    Contact
                  </Link>
                </Grid>
              </Grid.Container>
            </Grid>
          </Grid.Container>
        </Page.Header>
        <Page.Content>
          <Grid xs={24}>
            <Text h1>
              Hi, I'm <span style={{ color: accentColor }}>Kevin</span>
            </Text>
            <Text>
              I'm a <span style={accentedTextStyle}>Software Engineer</span> who
              loves to talk about{" "}
              <span style={accentedTextStyle}>
                Systems, Security, and Scalability
              </span>
              .
            </Text>
            <Text>
              When I'm not doing that, I enjoy music, trying and cooking new
              foods, and exploring new cafes.
            </Text>
          </Grid>
          <Grid xs={24} mt={3}>
            <Text h2 id="projects">
              Here's what I've done recently
            </Text>
          </Grid>
          <Grid.Container gap={1.5}>
            <Grid xs={24} s={12} md={6} justify="center">
              <Card width="100%">
                <Text h4 my={0}>
                  Polyrhythm Visualizer
                </Text>
                <Text>
                  Visualize complex rhythms with this Polyrhythm Visualizer
                </Text>
                <Card.Footer>
                  <Link
                    color
                    target="_blank"
                    href="https://polyrhythm.kengli.sh"
                  >
                    <Globe size={16} />
                    Open
                  </Link>
                </Card.Footer>
              </Card>
            </Grid>
            <Grid xs={24} s={12} md={6} justify="center">
              <Card width="100%">
                <Text h4 my={0}>
                  Distributed Consensus Visualization
                </Text>
                <Text>
                  Visualization of Snowball binary consensus in the browser with
                  adaptable parameters.
                </Text>
                <Card.Footer>
                  <Link
                    color
                    target="_blank"
                    href="https://consensus.kengli.sh"
                  >
                    <Globe size={16} />
                    Open
                  </Link>
                </Card.Footer>
              </Card>
            </Grid>
            <Grid xs={24} s={12} md={6} justify="center">
              <Card width="100%">
                <Text h4 my={0}>
                  RSA Python
                </Text>
                <Text>
                  An exercise in implementing RSA in pure python with a brief
                  writeup
                </Text>
                <Card.Footer>
                  <Link
                    color
                    target="_blank"
                    href="https://github.com/kev-vin/rsa-simple-py3"
                  >
                    <Github size={16} />
                    View on GitHub
                  </Link>
                </Card.Footer>
              </Card>
            </Grid>
          </Grid.Container>
          <Grid xs={24} mt={3}>
            <Text h2 id="contact">
              Get in touch
            </Text>
          </Grid>
          <Link href="mailto:kevin@kengli.sh">
            <Button shadow type="secondary" mr={1} mb={1}>
              Email
            </Button>
          </Link>
          <Link target="_blank" href="https://github.com/kev-vin">
            <Button shadow type="secondary" mb={1}>
              GitHub Profile
            </Button>
          </Link>
        </Page.Content>
      </Page>
    </>
  );
}

export default App;
